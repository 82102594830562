





































































































































































































import { mapState, mapActions } from 'vuex'
import { Request } from '@/store/modules/requests/types'
import Loading from '@/components/Loading/index.vue'
import RequestHeader from '@/components/Request/RequestHeader.vue'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import ValidatedBFormMaskedInput from '@/components/ui/ValidatedBFormMaskedInput.vue'
import ModalPickAddress from '@/components/ModalPickAddress/ModalPickAddress.vue'
import ModalActivate from '@/components/Request/ModalActivate/ModalActivate.vue'
import ModalCancel from '@/components/Request/ModalCancel/ModalCancel.vue'
import { cloneDeep } from '@/utils/functions'

export default {
  name: 'RequestDetail',

  components: {
    Loading,
    RequestHeader,
    ValidatedBFormInput,
    ValidatedBFormMaskedInput,
    ModalPickAddress,
    ModalActivate,
    ModalCancel
  },

  data () {
    return {
      saving: false,
      isShownAddressModal: false,
      isShownActivateModal: false,
      isShownCancelModal: false,
      localRequest: null as Request,
      loader: false,
      contactPhoneData: {
        valid: true
      }
    }
  },

  async created () {
    await this.getBusinessCategories()
    await this.getRequestDetails()
  },

  computed: {
    ...mapState('common', [
      'businessCategories',
      'loading'
    ]),
    ...mapState('requests', [
      'request'
    ]),
    disabledSave () {
      const dataIdentical = JSON.stringify(this.request) === JSON.stringify(this.localRequest)
      return dataIdentical || this.saving
    }
  },

  watch: {
    request (request) {
      this.localRequest = cloneDeep(request)
    },
    loading (loading) {
      this.loader = loading
    }
  },

  methods: {
    ...mapActions('common', [
      'getBusinessCategories'
    ]),
    ...mapActions('requests', [
      'updateRequest',
      'getRequest'
    ]),
    async getRequestDetails () {
      try {
        this.loader = true
        await this.getRequest(this.$route.params.requestId)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loader = false
      }
    },
    goBack () {
      history.back()
    },
    activateRequest () {
      this.isShownActivateModal = true
    },
    cancelRequest () {
      this.isShownCancelModal = true
    },
    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid || !this.contactPhoneData?.valid) return

      this.handleUpdateRequest()
    },

    async handleUpdateRequest () {
      const data = cloneDeep(this.localRequest)
      data.contact_phone = data.contact_phone.replaceAll(/[ +]/g, '')
      this.saving = true
      const result = await this.updateRequest(data)
      if (result.status < 200 || result.status >= 300) {
        this.$toast.error(result.data.detail.message)
        this.saving = false
        return
      }
      this.saving = false
      this.$toast.success('Данные обновлены')
    },

    saveAddress (location) {
      this.localRequest.location = location

      this.isShownAddressModal = false
    },

    validateContactPhone (...data) {
      const phoneObject = data.length > 1 ? data[1] : data[0]
      const number = data.length > 1 ? data[0] : ''
      this.contactPhoneData = { ...phoneObject, valid: phoneObject.valid || true }
      if (phoneObject.number && phoneObject.number.match(/[^\d+\s]/)) {
        this.contactPhoneData.valid = false
      }
      if (number && number.match(/[^\d+\s]/)) {
        this.contactPhoneData.valid = false
      }
    }
  }
}
