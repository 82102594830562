






































import { PropType } from 'vue'
import { mapActions } from 'vuex'
import Loading from '@/components/Loading/index.vue'
import { STATUSES } from '@/common/constants'

export default {
  name: 'ModalActivate',

  components: {
    Loading
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    id: {
      type: String as PropType<string>,
      default: ''
    },
    username: {
      type: String as PropType<string>,
      default: ''
    }
  },

  data () {
    return {
      loading: false,
      showed: true,
      result: false,
      resultTitle: 'Бизнес-профиль активирован!'
    }
  },

  watch: {
    value (val) {
      this.showed = val
    }
  },

  mounted () {
    this.showed = this.value
  },

  methods: {
    ...mapActions('requests', [
      'updateRequestStatus'
    ]),

    async activate () {
      const result = await this.updateRequestStatus({ requestId: this.id, status: { status: STATUSES.accepted } })

      if (result.status < 200 || result.status >= 300) {
        this.result = true

        this.resultTitle = result.data.detail || "Заявка уже имеет статус 'Активирован'"
        this.$toast.error(result.data.detail)
        return
      }

      this.result = true
    },

    returnToRequests () {
      this.$router.push('/profiles/requests')
    }
  }

}
