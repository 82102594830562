




































































import { PropType } from 'vue'
import { mapActions } from 'vuex'
import Loading from '@/components/Loading/index.vue'
import { STATUSES } from '@/common/constants'

export default {
  name: 'ModalCancel',

  components: {
    Loading
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    id: {
      type: String as PropType<string>,
      default: ''
    },
    username: {
      type: String as PropType<string>,
      default: ''
    }
  },

  data () {
    return {
      loading: false,
      showed: true,
      result: false,
      resultStatus: 200,
      rejectText: ''
    }
  },

  watch: {
    value (val) {
      this.showed = val
    }
  },

  mounted () {
    this.showed = this.value
  },

  methods: {
    ...mapActions('requests', [
      'updateRequestStatus'
    ]),

    async cancel () {
      const result = await this.updateRequestStatus({
        requestId: this.id,
        status: {
          status: STATUSES.rejected,
          reject_reason: this.rejectText
        }
      })

      if (result.status < 200 || result.status >= 300) {
        this.result = true

        this.resultStatus = result.status
        this.$toast.error(result.data.detail)
        return
      }
      this.result = true
    },

    returnToRequests () {
      this.$router.push('/profiles/requests')
    }
  }

}
