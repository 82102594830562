






















import Avatar from '@/components/Avatar.vue'
import NavigateBack from '@/components/NavigateBack.vue'
import { S3_URL, STATUSES } from '@/common/constants'

export default {
  name: 'RequestHeader',

  components: {
    Avatar,
    NavigateBack
  },

  props: {
    request: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      defaultAvatarUrl: `${S3_URL}/default_avatar.png`
    }
  },

  computed: {
    statusText () {
      let statusText
      switch (this.request.status) {
        case STATUSES.processing:
          statusText = 'Запрос на бизнес-профиль на рассмотрении'
          break

        default:
          break
      }
      return statusText
    }
  },

  methods: {
    async handleDeleteAvatar () {
      const updatedProfile = this.request

      updatedProfile.avatar_url = this.defaultAvatarUrl

      try {
        this.saving = true
        await this.request.updatedRequest
        this.$toast.success('Аватар удалён')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.saving = false
      }
    },

    async handleUploadedAvatar (url: string) {
      const updatedProfile = this.request

      updatedProfile.avatar_url = url

      try {
        this.saving = true
        await this.request.updatedRequest
        this.$toast.success('Аватар обновлен')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.saving = false
      }
    }
  }
}
